import React, { useEffect } from "react";
import styles from "./Account.module.css";
import LiveAnbox from "../LiveAnbox/LiveAnbox";
import { useState } from "react";
const Account = ({
  openHistory,
  openWithdraw,
  logout,
  defaultUser,
  userEdit,
  updater,
}) => {
  const [password, setPassword] = useState(defaultUser.password);
  const [isEdit, setEdit] = useState(false);
  const [updateS, setUpdateS] = useState(updater);


  const [isEditShipping, setIES] = useState(false);
  const [formValues, setFormValues] = useState(defaultUser
  );



  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const lastChar = value[value.length - 1];

    // Check if the last character is a letter, number, or whitespace
    const allowedCharacters = /^[a-zA-Z0-9\s+@]$/;
    if (allowedCharacters.test(lastChar) || value === '') {
      setFormValues({
        ...formValues,
        [name]: value
      });
    } else {
      event.preventDefault(); // Cancel the change if it's not allowed
    }
  };


  const toggleEdit = () => {
    setEdit(!isEdit);
    if (isEdit) {
      userEdit(formValues.email, password, formValues);
    }
  };
  const toggleEditShipping = () => {
    setIES(!isEditShipping);
    if (isEditShipping) {
      userEdit(formValues.email, password, formValues);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.leftbody}>
        <div className={styles.accounttop}>
          <a id={styles.back} href="/">
            ← Back
          </a>
          <div id={styles.chooseMenu}>
            <button id={styles.accountbtn}>Account</button>
            <button onClick={openHistory} id={styles.historybtn}>
              History
            </button>
            <button onClick={openWithdraw} id={styles.cashoutbtn}>
              Cash out
            </button>
          </div>
        </div>
        <h1 className={styles.fatheader}>Welcome to account management</h1>
        <form id={styles.personaldata}>
          <input
            className={styles.inputAccount}
            disabled={!isEdit}
            type="text"
            value={formValues.email}
            onChange={handleInputChange}
            name="email"
            placeholder="Email"
          />

          <input
            className={styles.inputAccount}
            disabled={!isEdit}
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </form>

        <button onClick={toggleEdit} id={styles.editpersonalbtn}>
          {isEdit ? "Save" : "Edit"} personal data
        </button>
        <h1 className={styles.fatheader}>Shipping address</h1>
        <p id={styles.shippingprompt}>
          Please fill in your data in latin characters so postal services could
          proceed with delivery. Thank you for your cooperation.
        </p>
        <form id={styles.personaldata}>
          <input
            className={styles.inputAccount}
            type="text"
            disabled={!isEditShipping}
            value={formValues.name}
            onChange={handleInputChange}
            name="name"
            placeholder="First name"
          />
          <input
            className={styles.inputAccount}
            type="text"
            disabled={!isEditShipping}
            value={formValues.lastName}
            onChange={handleInputChange}
            name='lastName'
            placeholder="Last name"
          />
          <input
            className={styles.inputAccount}
            type="text"
            disabled={!isEditShipping}
            value={formValues.address}
            name="address"
            onChange={handleInputChange}
            placeholder="Address"
          />
          <input
            className={styles.inputAccount}
            type="text"
            disabled={!isEditShipping}
            value={formValues.country}
            onChange={handleInputChange}
            name="country"
            placeholder="Country"
          />
          <input
            className={styles.inputAccount}
            type="text"
            disabled={!isEditShipping}
            name="state"
            value={formValues.state}
            onChange={handleInputChange}
            placeholder="State"
          />
          <input
            className={styles.inputAccount}
            type="text"
            disabled={!isEditShipping}
            name="city"
            value={formValues.city}
            onChange={handleInputChange}
            placeholder="City"
          />
          <input
            className={styles.inputAccount}
            type="text"
            disabled={!isEditShipping}
            value={formValues.code}
            name="code"
            onChange={handleInputChange}
            placeholder="Code"
          />
          <input
            className={styles.inputAccount}
            type="text"
            disabled={!isEditShipping}
            value={formValues.address_2}
            name="address_2"
            onChange={handleInputChange}
            placeholder="Address 2"
          />
          <input
            className={styles.inputAccount}
            name="phoneNumber"
            type="text"
            disabled={!isEditShipping}
            value={formValues.phoneNumber}
            onChange={handleInputChange}
            placeholder="Phone number"
          />
        </form>
        <button onClick={toggleEditShipping} id={styles.editshipaddress}>
          {isEditShipping ? "Save" : "Edit"} shipping address
        </button>
        <a onClick={logout} style={{ color: "black", fontWeight: "bold" }}>
          Log out
        </a>
      </div>
      <LiveAnbox />
    </div>
  );
};

export default Account;
