import React from "react";
import styles from "./Cryptoicons.module.css";
import btc from "../../assets/icon_BitcoinCryptocurrency_.png"
import eth from "../../assets/icon_EthereumClassicCryptocurrency_.png"
import tet from "../../assets/icon_TetherCryptocurrency_.png"
import bnc from "../../assets/icon_BinanceCoinCryptocurrency_.png"
import dog from "../../assets/icon_DogecoinCryptocurrency_.png"
import crc from "../../assets/icon_Crypto.comCryptocurrency_.png"

const Cryptoicons = () => (
    <div className={styles.cryptoicons}>
        <img className="infobutton" src={btc}></img>
        <img className="infobutton"  src={eth}></img>
        <img className="infobutton"  src={tet}></img>
        <img className="infobutton"  src={bnc}></img>
        <img className="infobutton"  src={dog}></img>
        <img className="infobutton"  src={crc}></img>
    </div>
);

export default Cryptoicons;
