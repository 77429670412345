import axios from "../config/axiosConfig";


export default {
  get: (page) => axios.get('/box/list', {params:{page}},
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`,
        'accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  )
    .then(response => {
      console.log(response);
      return response
    })
    .catch(error => {
      if (error.response.status == 403) {
        return ([{ sori: ":("}])
      } else {        
        console.log('Error making the get request', error);
        return({data:''})
      }
    })
};  