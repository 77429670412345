import React from "react";
import styles from "./Banner.module.css";
import pinkbg from "../../assets/banner1.svg";
import greenbg from "../../assets/banner2.svg";
import yellowbg from "../../assets/banner3.svg";

const banners = [pinkbg, yellowbg, greenbg];
const headers = [
  "",
  "",
  "",
];
const paragraphs = [
  "",
  "",
  "",
];

const Banner = ({ currentBanner, switchBanner }) => (
  <div style={{ display: "grid", overflow: "hidden",position:'relative' }}>
    <div
      style={{
        position: "relative",
        transform: `translateX(-${(((currentBanner - 1) % 3) * 100) / 3}%)`,
        transition: `transform 1000ms ease`,
        display: "flex",
      }}
    >
      {banners.map((banner, num) => (
        <div
        className={styles.bannercontainer} key={num} style={{ position: "relative",padding:"0px 10px" }}>
          <div
            className={styles.bannerText}
            style={{
              position: "absolute",
              top: "5vw",
              left: "5vw",
              maxWidth: "70%",
            }}
          >
            <h1 className={styles.header}>{headers[num]}</h1>
            <p className={styles.paragraph}>{paragraphs[num]}</p>
          </div>
          <img src={banner} className={styles.banner} />
        </div>
      ))}
    </div>
    <div id={styles.indicatorContainer}>  
      {banners.map((banner, id) => (<div key={id} id={id==(currentBanner-1)%3?(styles.selected):(styles.unselected)} className={styles.indicator}></div>
      ))}
    </div>
  </div>
);

export default Banner;
