import React, { useState,useEffect } from 'react';
import styles from './DepositPopup.module.css'
import anboxmelogo from '../../assets/Frame_4644.png';
import cross from '../../assets/close_button-removebg-preview.png';
import google from '../../assets/Google.png';
import facebook from "../../assets/Facebook.png";
import steam from "../../assets/Steam.png";
import box from '../../assets/boxwipads.png'


function DepositPopup({ togglePopup, onDeposit }) {
  const [depositAmount, setDepositAmount] = useState('');
  const [isRedirecting, setIsRedirecting] = useState(false)

  const [bronze, setBronze] = useState('')
  const [silver, setSilver] = useState('')
  const [gold, setGold] = useState('')

  


  useEffect(() => {
    
    const interval = setInterval(() => {
      try {
        setBronze(window.clientConfig.imageUrl + JSON.parse(localStorage.getItem('bronzeBox'))[0].image)
        setSilver(window.clientConfig.imageUrl + JSON.parse(localStorage.getItem('silverBox'))[0].image)
        setGold(window.clientConfig.imageUrl + JSON.parse(localStorage.getItem('goldBox'))[0].image)
      }
      catch {
        
      }
    }, 1000); // This runs every 1000ms (1 second)

    // Cleanup to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);


  var currentBonus = depositAmount > 20 ? (depositAmount > 75 ? (3) : (2)) : (1);

  const [error, setError] = useState("");
  const [notEnough, setNotEnough] = useState(false)


  const handleInputsChange = (event) => {
    const { name, value } = event.target;
    setDepositAmount(value)

    // Validation logic for depositAmount
    console.log(value, name)
    if (name === "amount") {
      if (value < 5 && value !== "") {
        setError("Minimum 5 USD");
        setNotEnough(true);
      } else {
        setError("");
        setNotEnough(false);
      }
    }
  };

  const buttonclicktest = () => {
    alert('You have just clicked on a button without an assigned action. Please assign an OnClick event to this button.')
  }


  return (
    <div className={styles.popup}>
      <div className={styles.popupinner}>
        <h1 id={styles.label}>Deposit via crypto</h1>
        <button onClick={togglePopup} className={styles.closebutton}><img style={{ height: "20px", width: "20px" }} alt='x' src={cross}></img></button>
        <div style={{ textAlign: "start" }}><p style={{ margin: 0 }}>AMOUNT IN $</p></div>
        <div className={styles.depositinput} >
          <input id={notEnough ? (styles.err) : ('')} style={{ width: "100%", paddingLeft: "20px", padding: "15px" }}
            placeholder=''
            type="number"
            name="amount"
            value={depositAmount}
            onChange={handleInputsChange}

          /></div>
        {error && <span className={styles.depositError}>{error}</span>}
        <p id={styles.hideSmall}>Selected bonus</p>
        <div className={styles.bonusboxes}>
          <div id={currentBonus == 1 ? (styles.selected) : ({})} className={styles.bonusbox}>
            <img className={styles.bonusimg} src={bronze}></img>
            <div>
              <p className={styles.bonuslabel}>Bronze up to $20</p>
              <p className={styles.minilabel}>Top prize cost $250</p>
            </div>
          </div>
          <div id={currentBonus == 2 ? (styles.selected) : ({})} className={styles.bonusbox}>
            <img className={styles.bonusimg} src={silver}></img>
            <div>
              <p className={styles.bonuslabel}>Silver $21-75</p>
              <p className={styles.minilabel}>Top prize cost $500</p>
            </div>
          </div>
          <div id={currentBonus == 3 ? (styles.selected) : ({})} className={styles.bonusbox}>
            <img className={styles.bonusimg} src={gold}></img>
            <div>
              <p className={styles.bonuslabel}>Gold $76+</p>
              <p className={styles.minilabel}>Top prize cost $1000</p>
            </div>
          </div>
        </div>
        <button disabled={notEnough} className={styles.submitbutton} onClick={() => { onDeposit(depositAmount); setIsRedirecting(true) }}>Complete deposit</button>
        {isRedirecting ? (<p>You are being redirected...</p>) : (<></>)}
      </div>
    </div>
  );
}

export default DepositPopup;