import React, { useState } from 'react';
import styles from './SuccessReq.module.css'
import cross from '../../assets/close_button-removebg-preview.png';


function SuccessReq({ closePopup }) {

  const buttonclicktest = () => {
    alert('You have just clicked on a button without an assigned action. Please assign an OnClick event to this button.')
  }

  return (
    <div className={styles.popup}>
      <div className={styles.popupinner}>
        <div className={styles.popupTop}>
          <button onClick={closePopup} className={styles.closebutton}><img style={{ height: "20px", width: "20px" }} alt='x' src={cross}></img></button>
            <p id={styles.name}>Congratulations!</p><p id={styles.info}> Your request is successful</p>
        </div>
        <div className={styles.popupBottom}>
          <button className={styles.submitbutton} onClick={closePopup}>See other boxes</button>
        </div>
      </div>
    </div>
  );
}

export default SuccessReq;