import React from "react";
import styles from "./Main.module.css";
import Banner from "../changeableBanner/Banner";
import localshipping from "../../assets/local_shipping.png";
import steps from "../../assets/steps.png";
import datacheck from "../../assets/data_check.png";
import LiveAnbox from "../LiveAnbox/LiveAnbox";
import infobtn from "../../assets/Info.svg";
import Cryptoicons from "../Cryptoicons/Cryptoicons";

const openBoxPage = (boxinfo) => {
  localStorage.setItem("currentBoxInfo", JSON.stringify(boxinfo));
  console.log("boxinfoMAIN", boxinfo);
  window.location.href = `/box/${boxinfo.id}`;
};

const Main = ({
  featuredboxes,
  currentBanner,
  onshowmore,
  boxesnum,
  switchBanner,
}) => (
  <>
  <div className={styles.container}>
    <div className={styles.leftbody}>
      <Banner switchBanner={switchBanner} currentBanner={currentBanner} />

      <h1 onClick={()=>window.location.href='/allboxes'} className={styles.fatheader}>All featured luxury boxes here →</h1>
      <div id={styles.featuredluxuryboxes}>
        {featuredboxes.slice(0, boxesnum).map((item, i) => (
          <div onClick={() => openBoxPage(item)} key={i} className={styles.box}>
            <div className={styles.mainBoxImg}>
              <img
                style={{ width: "100%", height: "100%" }}
                src={window.clientConfig.imageUrl + item.image}
              ></img>
              <div className={styles.boxName}>{item.name}</div>
            </div>

            <div className={styles.boxinfo}>
              <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
                <div
                  style={{
                    backgroundColor: "#FFC658",
                    borderRadius: "5px",
                    padding: "0 10px",
                    fontWeight: "bold",
                    fontFamily: "Arial",
                    display: "flex",
                    justifyContent: "center",
                    height: "fit-content",
                  }}
                >
                  {"$ "}
                  {item.price}
                </div>
              </div>
              <img className="infobutton" src={infobtn}></img>
            </div>

          </div>
        ))}
      </div>
      <button onClick={onshowmore} id={styles.showmorebutton}>
        Show More
      </button>
    </div>
    <LiveAnbox />

  </div>
              <div className={styles.bottom}>
              <div id={styles.howitworks}>
                <h1 className={styles.fatheader}>How it works</h1>
                <div className={styles.hiwinner}>
                  <div className={styles.exactlyhowitworks}>
                    <img className={styles.hiwimage} src={localshipping}></img>
                    <div>
                      <h2 className={styles.hiwheader}>Deliverable</h2>
                      <p className={styles.hiwtext}>
                        If you’ve decided to get you a physical item, delivery will go
                        to your door step
                      </p>
                    </div>
                  </div>
                  <div className={styles.exactlyhowitworks}>
                    <img className={styles.hiwimage} src={steps}></img>
                    <div>
                      <h2 className={styles.hiwheader}>Authentic</h2>
                      <p className={styles.hiwtext}>
                        From verified partners, your premium item is brand authenticated
                        every time
                      </p>
                    </div>
                  </div>
                  <div className={styles.exactlyhowitworks}>
                    <img className={styles.hiwimage} src={datacheck}></img>
                    <div>
                      <h2 className={styles.hiwheader}>Fair results</h2>
                      <p className={styles.hiwtext}>
                        Provably fair concept guarantees that every result is fair - and
                        you can check it yourself!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
    </div>
    <Cryptoicons></Cryptoicons>
  </>
);

export default Main;
