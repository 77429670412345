import React from 'react';
import styles from './NoFreeBoxesPopup.module.css'
import googlebutton from '../../assets/googlebutton.png'

import cross from "../../assets/close_button-removebg-preview.png";

const NoFreeBoxesPopup = ({ onSeeOtherBoxes, closePopup }) => {
  return (
    <div className='popup'>
      <div className={styles.stickymenu}>
        <button onClick={closePopup} className="close-button">
          <img
            style={{ height: "20px", width: "20px" }}
            alt="x"
            src={cross}
          ></img>
        </button>
        <div id={styles.Boxis}>New deposit, new bonus. Yes it's that easy.</div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button onClick={onSeeOtherBoxes} id={styles.confirm}>See other boxes</button>
        </div>
      </div>
    </div>
  );
};

export default NoFreeBoxesPopup;
