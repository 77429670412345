/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react";
import styles from "./StickyMenuTop.module.css";
import Anboxmelogo from "../../assets/Frame_4644.png";
import headimg from "../../assets/profile.svg";
import gift2 from "../../assets/gift.svg";
import plus from "../../assets/Plus.svg";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const StickyMenuTop = ({
  isloggedIn,
  toggleDP,
  toggleWP,
  balance,
  bonusBoxes,
  toggleLP,
  openBonusBox,
}) => {
  const [redIndicator, setRI] = useState(false);
  const [convertedBalance, setConvertedBalance] = useState(balance);
  useEffect(() => {
    try {
      setConvertedBalance(balance.toFixed(2));
    } catch (e) {}
  }, [balance]);

  const location = useLocation();

  const checkBonusBoxes = () => {
    const currentBoxId = location.pathname.split("/box/")[1];
    const targetBoxId = bonusBoxes[0];
    if (currentBoxId && parseInt(currentBoxId, 10) === targetBoxId) {
      console.log("Already on the target box page.");
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={styles.stickymenu}>
      <div className={styles.stickymenutop}>
        <div className={styles.leftside}>
          <a
            style={{
              padding: 0,
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
            href="/"
          >
            <img
              style={{
                height: "100%",
                width: "90%",
                objectFit: "contain",
                maxHeight: "30px",
              }}
              src={Anboxmelogo}
            />
          </a>
        </div>
        <div className={styles.rightside}>
          <a href="/faq" className={styles.stickymenuitem} id={styles.faq}>
            FAQ
          </a>
          {isloggedIn ? (
            <div
              id={styles.depositedamountview}
              className={styles.stickymenuitem}
            >
              <div>
                <p style={{ padding: 0, margin: 0 }}>{convertedBalance}</p>
              </div>
              <div>
                <button
                  onClick={toggleDP}
                  style={{
                    padding: "0px",
                    margin: 0,
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  <img id={styles.plus} src={plus}></img>
                </button>
              </div>
            </div>
          ) : (
            <button
              onClick={toggleLP}
              className={styles.stickymenuitem}
              id={styles.deposit}
            >
              Deposit
            </button>
          )}
          <img
            onClick={() => {
              checkBonusBoxes() ? null : openBonusBox();
            }}
            alt="bonus"
            style={{ backgroundColor: redIndicator ? "red" : "transparent" }}
            className={styles.stickymenuitem + " " + styles.containedImg}
            id={styles.bonus}
            src={gift2}
          />
          <img
            onClick={
              isloggedIn
                ? () => {
                    window.location.href = "/account";
                  }
                : toggleLP
            }
            className={styles.stickymenuitem + " " + styles.containedImg}
            id={styles.headimage}
            src={headimg}
          />
        </div>
      </div>
    </div>
  );
};

export default StickyMenuTop;
