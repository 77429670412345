import React, { useState } from "react";
import "./LoginPopup.css";
import anboxmelogo from "../../assets/Frame_4644.png";
import cross from "../../assets/close_button-removebg-preview.png";
import google from "../../assets/Google.png";
import facebook from "../../assets/Facebook.png";
import steam from "../../assets/Steam.png";
import googleIcon from "../../assets/googleIcon.png";

function LoginPopup({
  closePopup,
  handleLogin,
  handleLoginGoogle,
  openSignPopup,
  isError,
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isEmailError,setIEE] = useState(false)

  const isValidEmail=(email) =>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }


  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIEE(!isValidEmail(value))
  };

  const buttonclicktest = () => {
    alert(
      "You have just clicked on a button without an assigned action. Please assign an OnClick event to this button."
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin(email, password);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [email, password]);

  return (
    <div className="popup">
      <div className="popup-inner">
        <button onClick={closePopup} className="close-button">
          <img
            style={{ height: "20px", width: "20px" }}
            alt="x"
            src={cross}
          ></img>
        </button>
        <img
          style={{ margin: "15px", scale: "80%" }}
          alt="ANB⬭XME"
          src={anboxmelogo}
        ></img>
        <div className="choose-login-signup">
          <p
            style={{ display: "inline", fontWeight: "bold", color: "blue" }}
            onClick={openSignPopup}
          >
            Sign Up
          </p>
          <p
            style={{
              display: "inline",
            }}
          >
            Login
          </p>
        </div>

        {isError ? (
          <div id="erroraccountexists">
            <p style={{ color: "black" }}>The password is incorrect</p>
          </div>
        ) : (
          <></>
        )}

        <div className="login-other-options">
          <button onClick={handleLoginGoogle} className="login-option">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img style={{ width: 22, height: 21 }} src={googleIcon}></img>
              <p>Login with Google</p>
            </div>
          </button>
        </div>
        <div className="email-input">
          <input
            style={{ width: "100%", paddingLeft: "20px", padding: "15px" }}
            placeholder="Email*"
            type="email"
              value={email}
              onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="password-input">
          <input
            style={{ width: "100%", paddingLeft: "20px", padding: "15px" }}
            placeholder="Password*"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="remember-me-input">
          <input style={{ width: "20px", height: "20px" }} type="checkbox" />
          <p style={{ fontSize: "11px" }}>Remember me</p>
        </div>
        {isEmailError ? (<p style={{ color: 'red' }}>Invalid email adress</p>):(<></>)}
        <button
          disabled={isEmailError}
          className="submit-button"
          onClick={() => handleLogin(email, password)}
        >
          Submit
        </button>
        <div className="footer">
          <p onClick={closePopup}>Continue as a Guest</p>
          <p>
            Don't have an account?<a onClick={openSignPopup}>Sign-up</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LoginPopup;
